export default class TripStatus {
  getTripStatus() {
    return {
      name: 'Trip Status',
      options: [
        {
          name: 'Completed',
          key: 'N',
          is_checked: false,
          params: 'tripstatus',
        },
        {
          name: 'Reservation',
          key: 'R',
          is_checked: false,
          params: 'tripstatus',
        },
        {
          name: 'On Rent',
          key: 'T',
          is_checked: false,
          params: 'tripstatus',
        },
        {
          name: 'On Pay',
          key: 'P',
          is_checked: false,
          params: 'tripstatus',
        },
        {
          name: 'On Feedback',
          key: 'F',
          is_checked: false,
          params: 'tripstatus',
        },
        // this type of data unavailable in database,
        // {
        //   name: "Cancelled",
        //   key: "C",
        //   is_checked: false,
        //   params: "tripstatus"
        // }
      ],
      params: 'tripstatus',
      is_active: false,
    }
  }
}
